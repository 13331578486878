
import { defineComponent } from 'vue';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import { mapGetters, mapActions } from 'vuex';

export default defineComponent({
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
    }),
  },
});
